<template>
  <div v-if="label" class="container">
    <v-container>
      <MaireDialog
        title="Update label rules"
        description=""
        :open="updateModalOpen"
        :fn="saveLabel"
        :onClose="() => (updateModalOpen = false)"
      >
        <v-row :key="key" v-for="[key, rule] in Object.entries(rules)">
          <v-col cols="6">
            <p style="min-width: 300px">{{ key }}</p>
            <MaireChipsInput
              :color="key !== 'includeAnyLabels' ? maireBlue : undefined"
              field="text"
              :items="unwrapRule(key)"
              hide-input
              :closable="false"
            />
          </v-col>
          <v-col cols="6">
            <p :class="ruleChanged(key) ? 'strong' : ''" :style="ruleChanged(key) ? mairePurple : ''">
              New rules
            </p>
            <MaireChipsInput
              :color="key !== 'includeAnyLabels' ? maireBlue : undefined"
              field="text"
              :items="unwrapRule(key, rule)"
              hide-input
              :closable="false"
            />
          </v-col> </v-row
        >>
      </MaireDialog>
      <v-row>
        <div class="inline" style="width: 100%; padding-bottom: 40px">
          <v-breadcrumbs
            :items="[
              {
                title: 'Label management',
                disabled: false,
                href: '/labels',
              },
              {
                title: label.text,
                disabled: true,
                href: '/labels',
              },
            ]"
          ></v-breadcrumbs>
          <div style="float: right">
            <div
              style="display: inline-block; margin-right: 12px; vertical-align: middle"
              v-if="labelId && label?.rules"
            >
              <v-switch
                ref="checkbox"
                dense
                v-model="isActive"
                @update:modelValue="toggleLabelActivity"
                color="purple"
                hide-details
                label="Automatic Labeling"
              ></v-switch>
            </div>
            <div v-if="labelId" style="display: inline-block; vertical-align: middle; margin-right: 12px">
              <MaireButton
                text="Delete label"
                icon="far fa-trash"
                compact
                inverse
                :onClick="() => (deletionModalOpen = true)"
              />
            </div>
            <div style="display: inline-block; vertical-align: middle; margin-right: 12px" v-if="labelId">
              <MaireButton
                icon="far fa-eye"
                inverse
                text="Fetch preview"
                :disabledMessage="
                  !validRule ? 'Add some rules, otherwise it will match everything in your analyses.' : ''
                "
                :disabled="previewFetchInProgress || !validRule"
                :onClick="fetchRulePreview"
                compact
              />
            </div>
            <div style="display: inline-block; vertical-align: middle; margin-right: 12px" v-if="labelId">
              <MaireButton
                icon="far fa-robot"
                inverse
                text="Trigger labeling"
                :disabled="!(totalCount  && labelId)"
                :isLoading="labelingInProgress"
                disabledMessage="Fetch preview first to ensure that you're able to capture the keywords you need."
                :onClick="triggerLabelRule"
                compact
              />
            </div>
            <div v-if="labelId" style="display: inline-block; vertical-align: middle">
              <MaireButton
                :disabled="!totalCount"
                disabledMessage="Fetch preview first to ensure that you're able to capture the keywords you need."
                text="Save"
                :onClick="() => (updateModalOpen = true)"
                compact
              />
            </div>
            <div v-else style="display: inline-block; vertical-align: middle">
              <MaireButton
                disabledMessage="Fetch preview first to ensure that you're able to capture the keywords you need."
                text="Save"
                :onClick="createLabel"
                compact
              />
            </div>
          </div>
        </div>
      </v-row>
      <v-row>
        <v-col cols="4">
          <p class="s strong mb-2">Label name</p>
          <div class="label-name-input inputs">
            <v-text-field
              hide-details
              density="compact"
              type="string"
              variant="outlined"
              v-model="labelName"
              required
            >
            </v-text-field>
            <div style="max-width: 100px">
              <maire-button
                @click="saveName"
                compact
                v-if="labelName !== label.text && labelId"
                text="Save name"
              />
            </div>
          </div>
          <p class="s strong my-2">Label color</p>
          <div class="color-picker">
            <v-color-picker mode="hex" v-model="color"> </v-color-picker>
            <div style="max-width: 100px">
              <maire-button @click="saveColor" compact v-if="colorDifferent && labelId" text="Save color" />
            </div>
          </div>
        </v-col>
        <v-col cols="4" v-if="labelId">
          <MaireCard>
            <p class="s strong">Keywords that contain any of the words</p>
            <p class="xs">
              The keywords you want to contain label “Barrel saunas” should include any of the following
              words.
            </p>
            <MaireChipsInput
              :items="includeAnyKeywords"
              :color="maireBlue"
              @add="(val: string) => includeAnyKeywords.push(val)"
              @remove="(val: string) => handleRemove('includeAnyKeywords', val)"
            />
          </MaireCard>
          <MaireCard>
            <p class="s strong">Keyword must have all of the words</p>
            <p class="xs">Keywords must always contain the following words</p>

            <MaireChipsInput
              :items="includeAllKeywords"
              :color="maireBlue"
              @add="(val: string) => includeAllKeywords.push(val)"
              @remove="(val: string) => handleRemove('includeAllKeywords', val)"
            />
          </MaireCard>
          <MaireCard>
            <p class="s strong">Keyword must not have the words</p>
            <p class="xs">Keywords must not include any of the following words</p>
            <MaireChipsInput
              :items="excludeAnyKeywords"
              :color="maireBlue"
              @add="(val: string) => excludeAnyKeywords.push(val)"
              @remove="(val: string) => handleRemove('excludeAnyKeywords', val)"
            />
          </MaireCard>
        </v-col>
        <v-col cols="4" v-if="labelId">
          <MaireCard>
            <p class="s strong">Included in the following analyses</p>
            <p class="xs">
              This label is included in the below analyses. You can remove it from a specific analysis if you
              think it shouldn’t be there.
            </p>
            <MaireChipsInput
              :color="mairePurpleSecondary"
              :items="label.analyses"
              hide-input
              field="name"
              :closable="false"
            />
          </MaireCard>
          <MaireCard>
            <p class="s strong">Keywords with labels</p>
            <p class="xs">
              You can add labels to include the keywords that contain the following labels, to also include
              the label being created.
            </p>
            <MaireChipsInput
              :itemList="unselectedLabels"
              :items="includeAnyLabels"
              @add="handleLabelAdd"
              @remove="(val: Label) => handleRemove('includeAnyLabels', val)"
              field="text"
            />
          </MaireCard>
        </v-col>
      </v-row>
    </v-container>
    <MaireDialog
      :onClose="() => (deletionModalOpen = false)"
      :open="deletionModalOpen"
      description="Are you sure you
  want to delete this label? It will remove it from all keywords and analyses. This cannot be undone!"
      confirmText="Delete"
      :fn="deleteLabel"
    />
    <div class="label-list maire-scrollbar" v-if="totalCount > 0 || previewFetchInProgress">
      <p class="s mb-3" v-if="!previewFetchInProgress">
        Total number of matching keywords: <strong>{{ totalCount }}</strong>
      </p>
      <p v-if="totalCount > 10000" class="s strong my-2">Sample</p>
      <div class="table-content">
        <DataTable
          :items="previewData"
          :isLoading="previewFetchInProgress"
          :fields="fields"
          :actions="actions"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <p>
      H'mm. This place shouldn't exist. Did you navigate here directly? If not, please let someone at Maire
      know and we'll see what went wrong.
    </p>
  </div>
</template>
<style scoped>
.label-name-input {
  background-color: white;
}
.label-name-input span {
  color: black;
}
.container {
  margin: 12px 24px;
}
.label-list {
  width: 100%;
  padding: 50px 24px;
  border-radius: 0px !important;
}
.box {
  background-color: rgb(var(--v-theme-mairePurpleSecondary), 0.1);
  height: 250px;
  margin-bottom: 12px;
}
</style>
<script lang="ts" setup>
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";
import { computed, ref, watch, onMounted } from "vue";
import DataTable from "@/components/DataTable/DataTable.vue";
import MaireDialog from "@/components/MaireDialog/MaireDialog.vue";
import MaireCard from "@/components/MaireCard/MaireCard.vue";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import MaireChipsInput from "@/components/MaireChipsInput/MaireChipsInput.vue";
import { Field } from "@/types";
import KeywordCell from "@/components/DataTable/DataTableCells/KeywordCell.vue";
import { countryISOCode } from "@/components/KeywordTable/KeywordTable.vue";
import VolumeCell from "@/components/KeywordTable/VolumeCell.vue";
import LabelCell from "@/components/DataTable/DataTableCells/LabelCell.vue";
import { Label, LabelRuleDetails } from "@/store/modules/labels/types";
import { useLabelColor } from "@/composables/useLabelColor";
const { getLabelColor } = useLabelColor();
const store = useStore();
const fields = [
  {
    field: "text",
    name: "Keyword",
    component: () => KeywordCell,
    width: 16,
    aggregation: "count_distinct",
    tooltip: "Search term on Google",
  },
  {
    field: "location_name",
    name: "Loc",
    renderer: (value: any) => countryISOCode(value).toUpperCase(),
    width: 5,
    tooltip: "Search location",
  },
  {
    field: "language_code",
    name: "Lang",
    renderer: (value: any) => value.toUpperCase(),
    width: 5,
    tooltip: "Search language",
  },
  {
    field: "labels",
    name: "Labels",
    width: 16,
    component: () => LabelCell,
  },
  {
    field: "volume",
    name: "Volume",
    component: () => VolumeCell,
    aggregation: "sum",
    width: 8,
    tooltip: "Average monthly searches on Google.",
  },
  {
    field: "google_only_volume",
    name: "Google only volume",
    component: () => VolumeCell,
    aggregation: "sum",
    width: 8,
    tooltip: "Average monthly searches on Google.",
  },
] as Field[];
const route = useRoute();
const router = useRouter();
const isActive = ref(false);
const updateModalOpen = ref(false);
const deletionModalOpen = ref(false);
const previewFetchInProgress = ref(false);
const includeAnyKeywords = ref([] as string[]);
const includeAnyLabels = ref([] as Label[]);
const includeAllKeywords = ref([] as string[]);
const excludeAnyKeywords = ref([] as string[]);
const mairePurple = "rgb(var(--v-theme-mairePurple)";
const mairePurpleSecondary = "rgb(var(--v-theme-mairePurpleSecondary)";
const maireBlue = "rgb(var(--v-theme-maireDarkBlue)";

const rules = computed(() => ({
  includeAnyKeywords: includeAnyKeywords.value,
  includeAnyLabels: includeAnyLabels.value.map((label) => label.id).filter(Boolean),
  includeAllKeywords: includeAllKeywords.value,
  excludeAnyKeywords: excludeAnyKeywords.value,
}));
const previewData = ref([]);
const totalCount = ref(0);
const labelingInProgress = computed(() => store.state.loading.labelingInProgress)
const validRule = computed(() => Object.values(rules.value).some((val) => val.filter(Boolean).length));
const fetchRulePreview = async () => {
  previewFetchInProgress.value = true;
  const result = await store.dispatch("labels/labelRulePreview", rules.value);
  previewFetchInProgress.value = false;
  previewData.value = result.results;
  totalCount.value = result.total_count;
};
const dummyLabel = {
  id: undefined,
  text: "New Label",
  color: undefined,
  keyword_count: undefined,
  analyses: undefined,
  rules: undefined,
};
const labelId = computed(() => parseInt(route.params.id as string) as number);
const labels = computed(() => Object.values(store.state.labels.labels));
const unselectedLabels = computed(() => {
  const selected = includeAnyLabels.value.map((lbl) => lbl.id);
  return labels.value.filter((lbl) => !selected.includes(lbl.id));
});
const label = computed(() => {
  return labelId.value ? labels.value.find((lbl) => lbl.id === labelId.value) : dummyLabel;
});
const labelName = ref("");
const color = ref("");
const colorDifferent = computed(() => color.value !== getLabelColor(label.value?.text ?? ""));
const toggleLabelActivity = async () => {
  if (label.value?.id) {
    await store.dispatch("labels/toggleLabelRule", {
      labelId: label.value?.id,
      isActive: isActive.value,
      labelText: label.value.text,
    });
  }
};
const colorTimer = ref(0);
watch(labelName, () => {
  if (colorTimer.value) clearTimeout(colorTimer.value);
  if (!label.value?.color) {
    colorTimer.value = setTimeout(
      () => (color.value = labelName.value ? getLabelColor(labelName.value) : ""),
      500
    ) as unknown as number;
  }
});
watch(
  label,
  async () => {
    // Check if in current local state rules include the just changed label
    const labelInFilter = includeAnyLabels.value.find((lbl) => lbl.id);
    if (
      labelInFilter &&
      (labelInFilter.text !== label.value?.text || labelInFilter.color !== label.value?.color)
    ) {
      includeAnyLabels.value = includeAnyLabels.value.map((lbl) =>
        lbl.id === label.value?.id ? { ...lbl, text: label.value.text, color: label.value.color } : lbl
      );
    }

    // To have a nifty backgward-compatibility to ensure all previously labeled kws are part of the new logic
    // We automatically add a rule that the label-rule matches all keywords which alredy have the label.
    // This basically means that it will never delete the unlabeled keywords.
    if (labelId.value && label.value && !label.value?.rules) {
      includeAnyLabels.value = [label.value];
    }
    labelName.value = label.value?.text ?? "";

    // Use custom label color if set
    if (label.value?.color) {
      color.value = label.value.color;
    } else {
      color.value = label.value?.text ? getLabelColor(label.value?.text) : "";
    }
  },
  { immediate: true }
);
onMounted(async () => {
  if (!labelId.value) return;
  const r = await store.dispatch("labels/fetchRules", labelId.value);
  const labelRule = r.length > 0 ? r[0] : undefined;
  if (!labelRule) return;
  includeAnyKeywords.value = labelRule?.rule?.includeAnyKeywords ?? [];
  includeAnyLabels.value = labelRule?.rule?.includeAnyLabels ?? [];
  includeAllKeywords.value = labelRule?.rule?.includeAllKeywords ?? [];
  excludeAnyKeywords.value = labelRule?.rule?.excludeAnyKeywords ?? [];
  isActive.value = labelRule?.is_active ?? false;
});
const actions = [
  {
    icon: "fa-regular fa-trash",
    name: "Delete",
    fn: () => {
      deletionModalOpen.value = true;
    },
  },
];

const handleLabelAdd = (val: number) => {
  const labelToAdd = labels.value.find((lbl) => lbl.id === val);
  if (labelToAdd) {
    includeAnyLabels.value.push(labelToAdd);
  }
};
const handleRemove = (type: string, item: string | Label) => {
  if (type === "includeAnyKeywords") {
    includeAnyKeywords.value = includeAnyKeywords.value.filter((val) => val !== item);
  } else if (type === "includeAllKeywords") {
    includeAllKeywords.value = includeAllKeywords.value.filter((val) => val !== item);
  } else if (type === "excludeAnyKeywords") {
    excludeAnyKeywords.value = excludeAnyKeywords.value.filter((val) => val !== item);
  } else if (type === "includeAnyLabels" && typeof item === "object") {
    includeAnyLabels.value = includeAnyLabels.value.filter((val) => val.id !== item?.id);
  }
};
const deleteLabel = async () => {
  deletionModalOpen.value = false;
  if (label.value) {
    await store.dispatch("labels/deleteLabels", [label.value.text]);
    router.push("/labels");
  }
};
const saveLabel = async () => {
  updateModalOpen.value = false;
  if (label.value?.id) {
    await store.dispatch("labels/createLabelRule", {
      labelId: label.value?.id,
      labelText: label.value.text,
      rules: rules.value,
    });
  }
};
const createLabel = async () => {
  const lbl = await store.dispatch("labels/createLabel", {
    text: labelName.value,
    color: color.value,
  });
  if (lbl) {
    router.push(`/labels/${lbl.id}`);
  }
};

const triggerLabelRule = async () => {
  await store.dispatch("labels/triggerLabelRule", {
    labelId: label.value?.id,
    rules: rules.value,
  });
};
const saveColor = async () => {
  await store.dispatch("labels/updateLabel", { label: label.value, color: color.value });
};
const saveName = async () => {
  await store.dispatch("labels/updateLabel", { label: label.value, text: labelName.value });
};
const ruleChanged = (key: string) => {
  return (
    JSON.stringify(unwrapRule(key)) !==
    JSON.stringify(unwrapRule(key, rules.value?.[key as keyof LabelRuleDetails]))
  );
};
const unwrapRule = (key: string, rule?: Array<Label | string | number>) => {
  rule = rule ?? label.value?.rules?.[0]?.rule?.[key as keyof LabelRuleDetails];
  if (key === "includeAnyLabels") {
    return rule?.map((labelId) => labels.value.find((lbl) => lbl.id === labelId));
  } else {
    return rule;
  }
};
</script>
