<template>
  <MaireSelect @save="afterSave" v-if="selectProps" v-bind="selectProps" />
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { numberFormatter } from "@/components/helpers/helpers";
import MaireSelect from "@/components/MaireSelect/MaireSelect.vue";
import { useStore } from "@/store";
import { CheckboxState } from "@/store/modules/analysis/types";
import { Item } from "../DataTable/DataTable.vue";
import { Field } from "@/types";

export default defineComponent({
  name: "KeywordFilter",
  emits: ["save", "saveFilter"],
  props: {
    type: String,
    showSelect: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array as PropType<Item[]>,
      required: false,
    },
    field: {
      type: Object as PropType<Field>,
      required: false,
    },
    selected: {
      type: Array as PropType<CheckboxState[]>,
      required: false,
      default: () => [],
    },
  },
  setup() {
    const store = useStore();
    return { store };
  },
  methods: {
    numberFormatter,
    handleVolumeSave(value: number[]) {
      if (value?.length == 2 && value[1] > 0) {
        this.store.commit("analysis/setVolumeRangeSliderState", value);
      }
    },
    afterSave(cbs: CheckboxState[]) {
      this.$emit("save", { field: this.field?.field, cbs });
    },
    handlePositionSave(value: number[]) {
      if (value?.length == 2 && value[1] > 0) {
        this.store.commit("analysis/setPositionFilter", value, { root: true });
      }
    },
    handleTrendingSave(value: string[]) {
      this.store.dispatch("analysis/adjustTrendingFilter", value, { root: true });
    },
    handleCountrySave(value: string[]) {
      this.store.dispatch("analysis/adjustCountryFilter", value, { root: true });
    },
    handleLanguageSave(value: string[]) {
      this.store.dispatch("analysis/adjustLanguageFilter", value, { root: true });
    },
  },
  components: {
    MaireSelect,
  },
  computed: {
    volumeRange() {
      return this.store.state.analysis.currentFilters.volumeRange;
    },
    positionRange() {
      return this.store.state.analysis.currentFilters.positionRange;
    },
    sliderValues() {
      return this.store.state.analysis.currentFilters.volumeRange;
    },
    trendingCheckboxes() {
      return this.store.state.analysis.currentFilters.trendingFilter;
    },
    languageFilter() {
      return this.store.state.analysis.currentFilters.languageFilter;
    },
    countryFilter() {
      return this.store.state.analysis.currentFilters.countryFilter;
    },
    searchWord() {
      return this.store.state.analysis.currentFilters.searchWord;
    },
    labelFilter() {
      return this.store.state.analysis.currentFilters.labelFilters;
    },
    selectProps() {
      if (!this.type) return undefined;
      if (this.type === "volume") {
        return {
          handleSave: this.handleVolumeSave,
          sliderState: this.volumeRange,
          numberFormatter: this.numberFormatter,
          type: "slider",
          title: "Volume",
          showSelect: this.showSelect,
        };
      } else if (this.type === "position") {
        return {
          handleSave: this.handlePositionSave,
          sliderState: this.positionRange,
          numberFormatter: this.numberFormatter,
          type: "slider",
          title: "Position",
          showSelect: this.showSelect,
        };
      } else if (this.type === "trending") {
        return {
          handleSave: this.handleTrendingSave,
          checkboxes: this.trendingCheckboxes,
          type: "checkbox",
          title: "Trending",
          showSelect: this.showSelect,
        };
      } else if (this.type === "country" || this.type === "localization.location_name") {
        return {
          handleSave: this.handleCountrySave,
          checkboxes: this.countryFilter,
          type: "checkbox",
          title: "Country",
          showSelect: this.showSelect,
        };
      } else if (this.type === "language" || this.type === "localization.language_code") {
        return {
          handleSave: this.handleLanguageSave,
          checkboxes: this.languageFilter,
          type: "checkbox",
          title: "Language",
          showSelect: this.showSelect,
        };
      } else if (this.items && this.field) {
        const field = this.field;
        const uniqueValues = this.items
          .map((item) => {
            if (typeof item?.[field.field] === "object" && field.filtering_key) {
              return item?.[field.field]?.map((row: any) => row?.[field.filtering_key]);
            }
            return item?.[field.field];
          })
          .flat()
          .filter(Boolean);
        const uniqueFields = field ? new Set(uniqueValues) : [];
        return {
          handleSave: () => {},
          checkboxes: Array.from(uniqueFields).map((item) => ({
            text: item,
            checked: this.selected.includes(item),
          })),
          type: "checkbox",
          showSelect: this.showSelect,
        };
      } else {
        return undefined;
      }
    },
  },
});
</script>
