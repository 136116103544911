<template>
  <div @mousemove="setPos" @mouseleave="unsetPos">
    <span class="kw">
      <span ref="rowz" class="hover-white">{{ volume }} </span>
      <span v-if="left > 0" class="tooltip inline-block" :style="`top:${top}px;left:${left}px`">
        <strong>Latest volume from: {{ volumesFrom }}</strong>
        <ul>
          <li :key="volumeData.year" v-for="volumeData in volumesByYear">
            {{ volumeData.year }}: <strong>{{ volumeData.volume }}</strong>
          </li>
        </ul>
      </span>
    </span>
  </div>
</template>
<style scoped>
@keyframes rotation {
  from {
    transform: rotate(0deg);
    opacity: 0.2;
  }
  to {
    transform: rotate(359deg);
    opacity: 0.6;
  }
}
.tooltip {
  position: fixed;
  text-align: left;
  margin-top: -20px;
  margin-left: 20px;
  padding: 12px 24px;
  z-index: 999;
  background-color: rgb(var(--v-theme-mairePink));
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.3)) !important;
  border-radius: 10px;
  color: white;
}
.rotation-animation {
  animation: rotation 2s infinite linear;
  transform-origin: center center;
  display: inline-block;
}
.dot {
  height: 15px;
  width: 15px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: gray;
  display: inline-block;
}

.refresh {
  margin-left: 5px;
  text-align: center;
  cursor: pointer;
}
.bad {
  background-color: rgb(255, 220, 0);
  white-space: nowrap;
}

.good {
  background-color: rgb(0, 230, 0);
}

p {
  font-size: 10px;
  position: absolute;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import { numberFormatter } from "@/components/helpers/helpers";

export default defineComponent({
  name: "VolumeCell",
  props: {
    fieldValue: Number,
    item: Object,
  },
  data: () => ({
    left: 0,
    top: 0,
  }),

  methods: {
    setPos: function (ev: any) {
      this.left = ev.clientX;
      this.top = ev.clientY;
    },
    unsetPos: function () {
      this.left = 0;
      this.top = 0;
    },
  },
  computed: {
    volume() {
      if (this.fieldValue === null) return null;

      return numberFormatter(this.fieldValue || 0, 2);
    },
    volumesFrom() {
      if (!this.item?.volumes_from) {
        return "N/A";
      } else {
        const yearMonthString = this.item?.volumes_from;
        return `${yearMonthString.slice(4)}/${yearMonthString.slice(0, 4)}`;
      }
    },
    twoYearsAgo() {
      const currentYear = new Date().getFullYear();
      return (currentYear - 1).toString();
    },
    volumesByYear() {
      const volumeEntries = Object.entries(this.item?.volumes || {});
      let maxYear = 0;
      return volumeEntries.map(([year, volumes]) => {
        if (typeof Number(year) === "number" && Number(year) > maxYear) {
          maxYear = Number(year);
        }
        // @ts-ignore
        const volumesForYear: number = (volumes.filter(Boolean) as Array<number>).reduce(
          (acc: number, volume: number) => acc + volume,
          0
        );
        return {
          year: year === "proj" ? `${maxYear} Projected` : year,
          volume: numberFormatter(volumesForYear, 2),
        };
      });
    },
  },
});
</script>
