import { useStore } from "@/store";
import { computed } from "vue";

const COLORS = [
  "#B500FF",
  "#8200FF",
  "#3E54D3",
  "#293657",
  "#14CCC9",
  "#4FE0B5",
  "#E500FF",
  "#FF73FF",
  "#FFD100",
  "#B500FF",
];
export const useLabelColor = () => {
  const store = useStore();
  const labels = computed(() => store.state.labels.labels);
  const getLabelColor = (text: string, itemWithColor?: any) => {
    if(itemWithColor?.color) return itemWithColor.color

    const label = labels.value?.[text];
    if (label?.color) {
      return label.color;
    }
    let hash = 0;
    for (let i = 0; i < text?.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }
    const colorIndex = Math.abs(hash % COLORS.length);
    return COLORS[colorIndex];
  };

  return {
    getLabelColor,
  };
};
