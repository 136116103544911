<template>
  <MaireDialog
    :onClose="() => (deletionModalOpen = false)"
    :open="deletionModalOpen"
    description="Are you sure you
  want to delete this label? It will remove it from all keywords and analyses. This cannot be undone!"
    confirmText="Delete"
    :fn="() => console.log('deleted')"
  />
  <div :class="`card`">
    <div class="card-content inputs light">
      <div class="card-title">{{ title }}</div>
      <slot></slot>
      <div v-if="!hideInput">
        <v-text-field
          v-if="!itemList?.length"
          prepend-inner-icon="fa-regular fa-key-skeleton"
          density="compact"
          persistent-placeholder
          @keydown="handleKeydown"
          @paste="handlePaste"
          v-model="textValue"
          label="Descriptive words"
          placeholder="Add words here and press enter"
          variant="outlined"
          clearable
          outlined
        >
        </v-text-field>
        <v-autocomplete
          v-if="itemList?.length"
          :items="itemList"
          item-title="text"
          item-value="id"
          prepend-inner-icon="fa-regular fa-key-skeleton"
          density="compact"
          persistent-placeholder
          v-model="itemValue"
          @update:modelValue="saveLabel"
          label="Descriptive words"
          variant="outlined"
          clearable
          outlined
        >
        </v-autocomplete>
      </div>
      <div class="keyword-chips">
        <v-chip
          :style="{
            'background-color': color ?? getLabelColor(item?.[field], item),
            color: 'white',
          }"
          :v-bind="item"
          :key="item"
          density="comfortable"
          v-for="item in itemObjects"
          class="ma-1 keyword-chip"
        >
          {{ item?.[field] }}
          <template #close>
            <v-icon v-if="closable" icon="mdi:mdi-close-circle" @click.stop="() => handleClose(item)" />
          </template>
        </v-chip>
      </div>
    </div>
  </div>
</template>
<style scoped>
.card {
  position: relative;
  text-align: left;
  margin-bottom: 20px;
  padding: 12px 0px;
  color: black;
  background-color: white;
  border-radius: 10px;
  width: 100%;
}

.card-title {
  color: #8200ff;
  font-size: 16px;
  font-weight: 800;
  font-family: "Nunito Sans", extrabold;
  text-align: left;
}
</style>
<script lang="ts" setup>
import { useLabelColor } from "@/composables/useLabelColor";
import { defineProps, defineEmits, ref, computed } from "vue";
const { getLabelColor } = useLabelColor();

interface Item {
  [key: string]: string;
}
const emit = defineEmits(["click", "remove", "add", "addLabel", "removeLabel"]);
const props = defineProps({
  title: { type: String, required: false },
  field: { type: String, required: false, default: "value" },
  items: { type: Array as () => (Item | string)[], required: true, default: () => [] },
  itemList: { type: Array as () => (Item | string)[], required: true, default: () => [] },
  color: { type: String, required: false },
  requireConfirmation: { type: Boolean, required: false, default: () => false },
  hideInput: { type: Boolean, required: false, default: () => false },
  closable: { type: Boolean, required: false, default: () => true },
});

const textValue = ref("");
const itemValue = ref();
const deletionModalOpen = ref(false);
const handlePaste = () => {
  console.log("paste");
};
const saveLabel = () => {
  emit("add", itemValue.value);
};
const handleKeydown = (event: KeyboardEvent) => {
  if (textValue.value && event.code.includes("Enter")) {
    emit("add", textValue.value);
    textValue.value = "";
  }
};
const itemObjects = computed(() => {
  if (!props.items?.length) return [];
  const stringItems = typeof props.items?.[0] === "string";
  return (stringItems ? props.items.map((value) => ({ [props.field]: value })) : props.items) as Item[];
});
const handleClose = (item: Item | String) => {
  // @ts-ignore
  emit("remove", item?.value || item);
};
</script>
