import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-154907bd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "kw" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onMousemove: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setPos && _ctx.setPos(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.unsetPos && _ctx.unsetPos(...args)))
  }, [
    _createElementVNode("span", _hoisted_1, [
      _createElementVNode("span", {
        ref: "rowz",
        class: "hover-white"
      }, _toDisplayString(_ctx.volume), 513),
      (_ctx.left > 0)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "tooltip inline-block",
            style: _normalizeStyle(`top:${_ctx.top}px;left:${_ctx.left}px`)
          }, [
            _createElementVNode("strong", null, "Latest volume from: " + _toDisplayString(_ctx.volumesFrom), 1),
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.volumesByYear, (volumeData) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: volumeData.year
                }, [
                  _createTextVNode(_toDisplayString(volumeData.year) + ": ", 1),
                  _createElementVNode("strong", null, _toDisplayString(volumeData.volume), 1)
                ]))
              }), 128))
            ])
          ], 4))
        : _createCommentVNode("", true)
    ])
  ], 32))
}