import { createRouter, createWebHistory } from "vue-router";
import DiscoveryView from "@/views/DiscoveryView.vue";
import AnalysisView from "@/views/AnalysisView.vue";
import Chat from "@/views/Chat.vue";
import LoginView from "@/views/LoginView.vue";
import KeywordView from "@/views/KeywordView.vue";
import ArticleView from "@/views/ArticleView.vue";
import AdminView from "@/views/AdminView.vue";
import PerformanceView from "@/views/PerformanceView.vue";
import SettingsView from "@/views/SettingsView.vue";
import ImagesView from "@/views/ImagesView.vue";
import ArticlePreviewView from "@/views/ArticlePreviewView.vue";
import ContentCreationView from "@/views/ContentCreationView.vue";
import NewContentView from "@/views/NewContentView.vue";
import MyAnalysesVIew from "@/views/MyAnalysesVIew/MyAnalysesVIew.vue";
import LabelManagementView from "@/views/LabelManagementView/LabelManagementView.vue";
import LabelView from "@/views/LabelView/LabelView.vue";

export const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/analysis",
  },
  {
    path: "/chat",
    name: "chat",
    component: Chat,
  },
  {
    path: "/keywords",
    name: "keywords",
    component: DiscoveryView,
  },
  {
    path: "/analysis",
    name: "analysis_filters",
    component: MyAnalysesVIew,
  },
  {
    path: "/labels",
    name: "labels",
    component: LabelManagementView,
  },
  {
    path: "/labels/:id",
    name: "label",
    component: LabelView,
  },
  {
    path: "/analysis/:id",
    name: "analysis",
    component: AnalysisView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/reset_password/:token",
    name: "reset_password",
    component: LoginView,
  },
  {
    path: "/keywords/:id/article",
    name: "article",
    component: ArticleView,
  },
  {
    path: "/settings",
    name: "settings",
    params: {
      state: { type: "string" },
      code: { type: "string" },
      scope: { type: "string" },
    },
    component: SettingsView,
  },
  {
    path: "/performance",
    name: "performance",
    component: PerformanceView,
  },
  {
    path: "/images",
    name: "images",
    component: ImagesView,
  },
  {
    path: "/content",
    name: "contentCreationView",
    component: ContentCreationView,
  },
  {
    path: "/content/new",
    name: "newContentView",
    component: NewContentView,
  },
  {
    path: "/keywords/:id/article/:contentType",
    name: "articleWithType",
    params: { contentType: { type: "string", default: "guide" } },
    component: ArticleView,
  },
  {
    path: "/keywords/:id/article/preview",
    name: "articlePreview",
    component: ArticlePreviewView,
  },
  { path: "/keywords/:id", component: KeywordView },
  {
    path: "/admin",
    name: "admin",
    component: AdminView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
