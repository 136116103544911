import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { State as RootState } from "@/store/index";
import { LoadingState } from "./types";

const state: LoadingState = {
  loadingAnalysis: [],
  loadingLabelVolumes: [],
  downloadingAnalysisVolumes: [],
  loadingLabels: false,
  labelingInProgress: false,
  loadingArticle: false,
  loadingKeywords: false,
  loadingFavorites: false,
  loadingApp: false,
  loadingPrediction: false,
  thinking: false,
  loadingSerp: false,
  loadingSubtitles: false,
  loadingAbstract: false,
  loadingImage: false,
  loadingWordSuggestions: false,

  refreshInProgress: false,
  downloadingCSV: false,
  isCreatingKeyword: false,
  emailBeingSent: false,
  maireIsTyping: false,
};

// Mutations
const mutations: MutationTree<LoadingState> = {
  setLoadingArticle: (state, val: boolean) => (state.loadingArticle = val),
  setLoadingKeywords: (state, val: boolean) => (state.loadingKeywords = val),
  setLoadingFavorites: (state, val: boolean) => (state.loadingFavorites = val),
  setLoadingApp: (state, val: boolean) => (state.loadingApp = val),
  setLoadingPrediction: (state, val: boolean) => (state.loadingPrediction = val),
  setThinking: (state, val: boolean) => (state.thinking = val),
  setLoadingSerp: (state, val: boolean) => (state.loadingSerp = val),
  setLoadingSubtitles: (state, val: boolean) => (state.loadingSubtitles = val),
  setLoadingAbstract: (state, val: boolean) => (state.loadingAbstract = val),
  setLoadingImage: (state, val: boolean) => (state.loadingImage = val),
  setLoadingWordSuggestions: (state, val: boolean) => (state.loadingWordSuggestions = val),
  addAnalysisToDownloadingVolumes: (state, analysisId: number) =>
    state.downloadingAnalysisVolumes.push(analysisId),
  removeAnalysisFromDownloadingVolumes: (state, analysisId: number) =>
    (state.downloadingAnalysisVolumes = state.downloadingAnalysisVolumes.filter(
      (afId) => afId !== analysisId
    )),
  addFilterToLoadingLabelVolumes: (state, filterId: number) => state.loadingLabelVolumes.push(filterId),
  removeFilterFromLoadingLabelVolumes: (state, filterId: number) =>
    (state.loadingLabelVolumes = state.loadingLabelVolumes.filter((id) => id !== filterId)),
  refreshInProgress: (state, val: boolean) => (state.refreshInProgress = val),
  addFilterToLoadingAnalysis(state, filterId: number) {
    if (!state.loadingAnalysis.includes(filterId)) {
      state.loadingAnalysis.push(filterId);
    }
  },
  removeFilterFromLoadingAnalysis(state, filterId: number) {
    state.loadingAnalysis = state.loadingAnalysis.filter((id) => id !== filterId);
  },
  setDownloadingCSV: (state, val: boolean) => (state.downloadingCSV = val),
  setIsCreatingKeyword: (state, val: boolean) => (state.isCreatingKeyword = val),
  setEmailBeingSent: (state, val: boolean) => (state.emailBeingSent = val),
  setMaireIsTyping: (state, val: boolean) => (state.maireIsTyping = val),
  setLoadingLabels: (state, val: boolean) => (state.loadingLabels = val),
  setLabelingInProgress: (state, val: boolean) => (state.labelingInProgress = val),
};

// Actions
const actions: ActionTree<LoadingState, RootState> = {
  async loadingApp({ commit }, loading: boolean) {
    commit("setLoadingApp", loading);
  },
};

const loadingModule: Module<LoadingState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default loadingModule;
