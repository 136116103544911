<template>
  <div :class="`table-container ${loading ? 'loading' : ''}`">
    <DataTable
      :items="keywords"
      :viewId="viewId"
      :fields="fields"
      :handleRowClick="handleRowClick"
      :isLoading="isLoading"
      :hideFields="hideFields"
    />
  </div>
</template>
<style scoped>
.loading {
  filter: blur(4px) brightness(100%);
}
.table-container {
  transition: filter 0.5s ease-in-out;
}
</style>
<script lang="ts">
import { Field } from "@/types";
import { defineComponent } from "vue";
import DataTable from "@/components/DataTable/DataTable.vue";
import KeywordCell from "@/components/DataTable/DataTableCells/KeywordCell.vue";
import DifficultyCell from "./DifficultyCell.vue";
import PositionCell from "./PositionCell.vue";
import TrendingCell from "./TrendingCell.vue";
import LabelCell from "@/components/DataTable/DataTableCells/LabelCell.vue";
import VolumeCell from "./VolumeCell.vue";
import { CPC, Keyword } from "@/store/modules/keywords/types";
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "KeywordTable",
  components: {
    DataTable,
  },
  props: {
    keywords: { required: true, type: Object as () => Keyword[] },
    viewId: { required: false, type: Number },
    hideFields: { required: false, type: Array as () => string[] },
    loading: { required: false, type: Boolean, default: false },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    return { store, route, router };
  },
  data: () => ({
    numberChoicesVisible: false,
    sortAscending: false,
    modalOpen: false,
    selectedKeyword: {} as Keyword,
    sortColumn: "volume",
    searchWord: "",
    elementsPerPage: 10,
    currentPage: 1,
    fields: [
      {
        field: "keyword",
        name: "Keyword",
        component: () => KeywordCell,
        width: 16,
        aggregation: "count_distinct",
        tooltip: "Search term on Google",
      },
      {
        field: "trending",
        name: "Trending",
        component: () => TrendingCell,
        aggregation: "average",
        postFix: "%",
        width: 7,
        tooltip:
          "The percentage indicates the change in search volume between the latest month and the highest search volume from the past 12 months.",
      },
      {
        field: "labels",
        name: "Labels",
        width: 16,
        component: () => LabelCell,
      },
      {
        field: "localization.location_name",
        name: "Loc",
        renderer: (value: any) => countryISOCode(value).toUpperCase(),
        width: 5,
        tooltip: "Search location",
      },
      {
        field: "localization.language_code",
        name: "Lang",
        renderer: (value: any) => value.toUpperCase(),
        width: 5,
        tooltip: "Search language",
      },
      {
        field: "position",
        name: "Position",
        component: () => PositionCell,
        aggregation: "average",
        width: 8,
        tooltip: "Your domain's position in Google for this keywords",
      },
      {
        field: "volume",
        name: "Volume",
        component: () => VolumeCell,
        aggregation: "sum",
        width: 8,
        tooltip: "Average monthly searches on Google.",
      },
      {
        field: "cpc",
        name: "CPC",
        renderer: (value: CPC) => `${parseFloat(value.high.toString()).toFixed(2)}€`,
        width: 8,
        aggregation: "average",
        tooltip: "Cost per click for paid search advertising",
      },
      {
        field: "difficulty",
        name: "Competition",
        component: () => DifficultyCell,
        aggregation: "average",
        width: 10,
        tooltip: "Paid competition",
      },
      {
        field: "organic_difficulty",
        name: "Difficulty",
        component: () => DifficultyCell,
        aggregation: "average",
        width: 8,
        tooltip: "Organic difficulty",
      },
    ] as Field[],
  }),
  computed: {
    thinking() {
      return this.store.state.loading.thinking;
    },
    isLoading() {
      return this.store.state.loading.loadingKeywords || this.store.state.loading.loadingFavorites;
    },
    errorText() {
      return this.store.state.errorText;
    },
    publicPath() {
      return process.env.BASE_URL;
    },
  },
  methods: {
    handleRowClick(e: any, keyword: Keyword) {
      const isButton = e.target.className.includes("v-btn");
      const isActionIcon = e.target.className.includes("mdi") || e.target.className.includes("fa");
      const preventClick = e.target.className.includes("preventClick");
      const isInput = e.target.localName.includes("input") || e.target.innerHTML.includes("input");
      const isLabel =
        e.target.className.includes("label") ||
        e.target.className.includes("v-autocomplete") ||
        e.target?.parentElement?.className?.includes("label");
      if (!isActionIcon && !isLabel && !isButton && !preventClick && !isInput) {
        if (e.metaKey || e.ctrlKey) {
          window.open(`/keywords/${keyword.id}`, "_blank");
        } else {
          this.router.push(`/keywords/${keyword.id}`);
        }
      }
    },
    refreshSerp(kw_id: number) {
      this.store.dispatch("keywords/getKeywordDetails", {
        kw_id: kw_id,
        loadedInTable: true,
      });
    },
    selectKeyword(keyword: string) {
      this.keywords.forEach((kw) => {
        if (kw.keyword === keyword) {
          kw.hidden = true;
        }
      });
    },
  },
});

export const countryISOCode = (country: string) => {
  const countryMap: any = {
    Algeria: "DZ",
    Angola: "AO",
    Azerbaijan: "AZ",
    Argentina: "AR",
    Australia: "AU",
    Austria: "AT",
    Bahrain: "BH",
    Bangladesh: "BD",
    Armenia: "AM",
    Belgium: "BE",
    Bolivia: "BO",
    Brazil: "BR",
    Bulgaria: "BG",
    "Myanmar (Burma)": "MM",
    Cambodia: "KH",
    Cameroon: "CM",
    Canada: "CA",
    "Sri Lanka": "LK",
    Chile: "CL",
    Taiwan: "TW",
    Colombia: "CO",
    "Costa Rica": "CR",
    Croatia: "HR",
    Cyprus: "CY",
    Czechia: "CZ",
    Denmark: "DK",
    Ecuador: "EC",
    "El Salvador": "SV",
    Estonia: "EE",
    Finland: "FI",
    France: "FR",
    Germany: "DE",
    Ghana: "GH",
    Greece: "GR",
    Guatemala: "GT",
    "Hong Kong": "HK",
    Hungary: "HU",
    India: "IN",
    Indonesia: "ID",
    Ireland: "IE",
    Israel: "IL",
    Italy: "IT",
    "Cote d'Ivoire": "CI",
    Japan: "JP",
    Kazakhstan: "KZ",
    Jordan: "JO",
    Kenya: "KE",
    "South Korea": "KR",
    Latvia: "LV",
    Lithuania: "LT",
    Malaysia: "MY",
    Malta: "MT",
    Mexico: "MX",
    Morocco: "MA",
    Netherlands: "NL",
    "New Zealand": "NZ",
    Nicaragua: "NI",
    Nigeria: "NG",
    Norway: "NO",
    Pakistan: "PK",
    Panama: "PA",
    Paraguay: "PY",
    Peru: "PE",
    Philippines: "PH",
    Poland: "PL",
    Portugal: "PT",
    Romania: "RO",
    "Saudi Arabia": "SA",
    Senegal: "SN",
    Serbia: "RS",
    Singapore: "SG",
    Slovakia: "SK",
    Vietnam: "VN",
    Slovenia: "SI",
    "South Africa": "ZA",
    Spain: "ES",
    Sweden: "SE",
    Switzerland: "CH",
    Thailand: "TH",
    "United Arab Emirates": "AE",
    Tunisia: "TN",
    Turkey: "TR",
    Ukraine: "UA",
    "North Macedonia": "MK",
    Egypt: "EG",
    "United Kingdom": "GB",
    "United States": "US",
    "Burkina Faso": "BF",
    Uruguay: "UY",
    Venezuela: "VE",
  };
  return countryMap[country] || "country";
};
</script>
