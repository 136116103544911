<template>
  <div :class="`${!!disabled ? 'disabled' : ''}`" @mousemove="setPos" @mouseleave="unsetPos">
    <v-btn
      v-if="!onClick"
      :to="link"
      :disabled="!!disabled"
      :class="`button ${imageUrl ? 'small-button' : ''} ${compact ? 'compact' : ''} ${
        comfortable ? 'comfortable' : ''
      } ${inverse ? 'inverse' : ''}`"
      :density="compact ? 'compact' : comfortable ? 'comfortable' : 'default'"
    >
      <v-icon v-if="icon">{{ icon }}</v-icon>
      <span>{{ text }}</span>
      <span v-if="subtext" style="font-size: 8px"><br />{{ subtext }}</span>
    </v-btn>
    <v-btn
      v-if="onClick"
      @click="onClick as unknown"
      :disabled="!!disabled"
      :class="`button ${imageUrl ? 'small-button' : ''} ${fixed ? 'fixed' : ''} ${compact ? 'compact' : ''} ${
        comfortable ? 'comfortable' : ''
      } ${inverse ? 'inverse' : ''}`"
      :density="compact ? 'compact' : comfortable ? 'comfortable' : 'default'"
    >
      <v-progress-circular size="18" indeterminate v-if="isLoading" />
      <span v-if="!isLoading">
        <v-icon v-if="icon">{{ icon }}</v-icon>
        <span>{{ text }}</span>
        <span v-if="subtext" style="font-size: 8px"><br />{{ subtext }}</span>
      </span>
    </v-btn>
    <span
      v-if="left > 0 && disabled && disabledMessage"
      class="tooltip inline-block"
      :style="`top:${top}px;left:${left}px`"
    >
      {{ disabledMessage }}
    </span>
  </div>
</template>
<style scoped>
.disabled {
  cursor: not-allowed !important;
}
.tooltip {
  position: fixed;
  text-align: left;
  margin-top: -20px;
  margin-left: 20px;
  padding: 12px 24px;
  z-index: 999;
  background-color: rgb(var(--v-theme-mairePink));
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.3)) !important;
  border-radius: 10px;
  color: white;
  font-size: 10px;
}
i {
  margin-right: 8px;
}
.fixed {
  position: fixed !important;
  width: 300px !important;
}

.button {
  box-shadow: none;
  border-radius: 8px;
  background-color: #e500ff;
  color: white;
  text-align: center;
  font-weight: 800;
  width: 100%;
  padding: 25px;
  vertical-align: bottom;
  font-family: "Nunito Sans";
  cursor: pointer;
  display: flex
}
.comfortable {
  padding: 20px;
}

.comfortable {
  font-size: 12px !important;
}

.inverse {
  background-color: rgba(0, 0, 0, 0);
  color: rgb(var(--v-theme-mairePurple));
  border: 1px solid rgb(var(--v-theme-mairePurple));
}

.button:hover {
  filter: brightness(1.1);
}
.small-button {
  max-width: 150px;
}
.compact {
  padding: 14px;
}

.compact {
  font-size: 10px !important;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MaireButton",
  data: () => ({
    left: 0,
    top: 0,
  }),
  props: {
    text: String,
    link: String,
    disabled: Boolean,
    disabledMessage: String,
    imageUrl: String,
    subtext: { type: String, required: false },
    icon: { type: String, required: false },
    compact: { type: Boolean, default: false },
    comfortable: { type: Boolean, default: false },
    inverse: { type: Boolean, default: false },
    fixed: { type: Boolean, required: false },
    onClick: { type: Function, required: false },
    isLoading: { type: Boolean, default: false },
  },
  methods: {
    setPos(ev: any) {
      this.left = ev.clientX;
      this.top = ev.clientY;
    },
    unsetPos() {
      this.left = 0;
      this.top = 0;
    },
  },
});
</script>
