import * as api from "@/api";
import { State as RootState } from "@/store/index";
import { ActionContext, ActionTree, Module, MutationTree } from "vuex";
import { Label, LabelRule, LabelsState, ViewLabel } from "./types";
import { AxiosError } from "axios";

const getDefaultState = () => ({
  labels: {},
  viewLabels: {},
  analysisLabels: {},
});

// Mutations
const mutations: MutationTree<LabelsState> = {
  resetState: (state) => {
    state = Object.assign(state, getDefaultState());
  },
  removeLabels: (state, labels: string[]) => {
    state.labels = Object.fromEntries(
      Object.entries(state.labels).filter(([_, label]) => !labels.includes(label.text))
    );
  },
  updateLabel: (state, lbl: Label) => {
    const label = state.labels?.[lbl.text];
    if (label) {
      state.labels = { ...state.labels, [label.text]: lbl };
    }
  },
  setLabels: (state, labels: Record<string, Label>) => {
    state.labels = labels;
  },
  appendLabels: (state, labels: Record<string, Label>) => {
    state.labels = { ...state.labels, ...labels };
  },
  setViewLabels: (state, data: { viewId: number; viewLabels: ViewLabel[] }) => {
    state.viewLabels[data.viewId] = data.viewLabels;
  },
  setLabelsForAnalysis: (state, data: { analysisId: number; labels: Label[] }) => {
    state.analysisLabels[data.analysisId] = data.labels;
  },
  appendLabelsForAnalysis: (state, data: { analysisId: number; labels: Label[] }) => {
    const { analysisId, labels } = data;
    const existingLabels = state.analysisLabels[data.analysisId] ?? [];
    const existingLabelIds = new Set(existingLabels.map((lbl) => lbl.id));
    const newLabels = labels.filter((label) => !existingLabelIds.has(label.id));
    state.analysisLabels[analysisId] = existingLabels.concat(newLabels);
  },
  toggleLabelActivity: (state, data: { labelText: string; isActive: boolean }) => {
    const { labelText, isActive } = data;
    const rule = state.labels?.[labelText]?.rules?.[0];
    if (rule) {
      rule.is_active = isActive;
    }
  },
  replaceRules: (state, data: { labelText: string; rules: LabelRule[] }) => {
    const { labelText, rules } = data;
    const label = state.labels?.[labelText];
    if (label) {
      label.rules = rules;
    }
  },
};

// Actions
const actions: ActionTree<LabelsState, RootState> = {
  async fetchLabels({ commit, rootState }: ActionContext<LabelsState, RootState>) {
    if (rootState.loading.loadingLabels) return;
    commit("loading/setLoadingLabels", true, { root: true });
    const r = await api.fetchLabels();
    commit("loading/setLoadingLabels", false, { root: true });
    if (r.status === 200) {
      commit("setLabels", Object.fromEntries(r.data.map((label: Label) => [label.text, label])));
    } else {
      commit("setErrorText", "Something went wrong. Please try again later.", { root: true });
    }
  },
  async labelRulePreview({ commit }, rules: object) {
    const r = await api.fetchLabelRulePreview(rules);
    if (r.status >= 200) {
      return r.data;
    } else {
      commit("setErrorText", "Something went wrong. Please try again later.", { root: true });
    }
    return r.data;
  },
  async fetchRules(_, labelId: number) {
    const r = await api.fetchLabelRules(labelId);
    return r.data;
  },
  async triggerLabelRule({ commit }, { labelId, rules }: { labelId: number; rules: object }) {
    commit("loading/setLabelingInProgress", true, { root: true });
    const r = await api.triggerLabelRule(labelId, rules);
    if (r.status >= 200) {
      return r.data;
    } else {
      commit("setErrorText", "Something went wrong. Please try again later.", { root: true });
    }
    return r.data;
  },
  async toggleLabelRule(
    { commit },
    { labelId, labelText, isActive }: { labelId: number; labelText: string; isActive: boolean }
  ) {
    const r = await api.toggleLabelRule(labelId, isActive);
    if (r.status >= 200) {
      const msg = isActive
        ? "Automatic labeling toggled. The rule will now periodically check for keywords matching the rule and apply the label."
        : 'Automatic labeling disabled. You can apply the labels manually by pressing the "Trigger labeling" -button';
      commit("setSuccessText", msg, { root: true });
      commit("toggleLabelActivity", { labelText, isActive });
      return r.data;
    } else {
      commit("setErrorText", "Something went wrong. Please try again later.", { root: true });
    }
    return r.data;
  },
  async createLabelRule(
    { commit },
    { labelId, rules, labelText }: { labelId: number; labelText: string; rules: object }
  ) {
    const r = await api.createLabelRule(labelId, rules);
    if (r.status >= 200) {
      commit("replaceRules", { labelText, rules: [r.data] });
      commit("setSuccessText", "Label rule saved", { root: true });
      return r.data;
    } else {
      commit("setErrorText", "Something went wrong. Please try again later.", { root: true });
    }
    return r.data;
  },

  async createLabel(
    { state, commit }: ActionContext<LabelsState, RootState>,
    data: { text: string; color?: string }
  ) {
    try {
      const r = await api.createLabel(data);
      commit("setLabels", { ...state.labels, [data.text]: r.data });
      return r.data;
    } catch (e: any) {
      const msg = e?.response?.data?.detail ?? e?.message ?? "Something went wrong. Please try again later.";
      commit("setErrorText", msg, { root: true });
    }
  },
  async deleteLabels({ commit }: ActionContext<LabelsState, RootState>, labels: string[]) {
    if (!labels?.length) {
      commit("setErrorText", "You tried to delete labels without specifying which one.", { root: true });
    }
    const r = await api.deleteLabels(labels);
    if (r.status >= 200) {
      commit("setSuccessText", `Label(s) ${labels.join(", ")} successfully deleted.`, { root: true });
      commit("removeLabels", labels);
    } else {
      commit("setErrorText", "Something went wrong. Please try again later.", { root: true });
    }
  },

  async fetchViewLabels({ commit }: ActionContext<LabelsState, RootState>, data: { viewId: number }) {
    const r = await api.fetchViewLabels(data.viewId);
    const viewLabels = r.data;
    commit("setViewLabels", { viewId: data.viewId, viewLabels });
  },

  async saveViewLabels(
    { commit, dispatch }: ActionContext<LabelsState, RootState>,
    data: { viewId: number; viewLabels: ViewLabel[] }
  ) {
    const { viewId, viewLabels } = data;
    const labelsByLevel: { [level: number]: string[] } = {};
    const output: { level: number; labels: string[] }[] = [];
    viewLabels.forEach((label) => {
      if (!labelsByLevel[label.level]) {
        labelsByLevel[label.level] = [];
      }
      labelsByLevel[label.level].push(label.text);
    });
    Object.entries(labelsByLevel).forEach(([level, labels]) => {
      output.push({ level: parseInt(level), labels });
    });
    const r = await api.updateViewLevels(viewId, output);
    if (r.status === 200) {
      commit("setViewLabels", { viewId, viewLabels });
      commit("analysis/resetKeywordLabelsForLevels", viewId, { root: true });
      dispatch(
        "selectView",
        {
          viewId: viewId,
          level: 1,
        },
        { root: true }
      );
    }
  },
  async fetchLabelsInAnalysis(
    { commit, dispatch }: ActionContext<LabelsState, RootState>,
    analysisId: number
  ) {
    const r = await api.fetchLabelsInAnalysis(analysisId);
    if (r.status === 200) {
      commit("setLabelsForAnalysis", { analysisId, labels: r.data });
    }
  },
  async updateLabel({ state, commit }, data: { label: Label; color: string; text: string }) {
    try {
      await api.updateLabel(data.label.id, { color: data.color, text: data.text });
      commit("setSuccessText", "Label updated!", { root: true });
      const labels = state.labels;
      const text = data.text ?? data.label.text;
      const color = data.color ?? data.label.color;
      const newLabels = Object.fromEntries(
        Object.entries(labels).map(([key, value]) => {
          return key === data.label.text ? [text, { ...value, text, color }] : [key, value];
        })
      );
      commit("setLabels", newLabels);
    } catch (e: any) {
      const msg = e?.response?.data?.detail ?? e?.message ?? "Something went wrong. Please try again later.";
      commit("setErrorText", msg, { root: true });
    }
  },
};

const labelsModule: Module<LabelsState, RootState> = {
  namespaced: true,
  state: getDefaultState(),
  mutations,
  actions,
};

export default labelsModule;
